import React, { useContext, useEffect, useState } from 'react';
import {
    ContainerListHistory,
    CardHistory,
    CardHistoryHeader,
    CardHistoryBody,
    CardItem,
    ContainerTableHistory,
    HeaderTable,
    ContentTable,
    ContainerContentTable,
    RowTable,
    ContainerLoadMore,
    ContainerCenter,
    ContainerMain,
    NoDataFound,
    TextoLoading,
    ButtonContainer
} from './styled';
import { AppContextUser } from '../../../shared/context/user';
import { Button, Loading } from '@componentsShared';
import Viewport from '../../../shared/hooks/viewport';
import { getData } from '@utils/transferData';
import siteUrl from '@utils/siteUrl';
import { carServices } from '@services/car';
import { decode } from 'base64-arraybuffer';
import { systemServices } from '@services/system';

const TableShoppingHistoryDetail = () => {
    const NO_DATA_FOUND = 'No se encontraron resultados';
    const TIEMPO_EXCEDIDO = 'Se alcanzo el tiempo máximo de espera, no se obtuvieron resultados';
    const { dataUser } = useContext(AppContextUser);
    const [dataHistoryDetail, setDataHistoryDetail] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [userLogged, setUserLogged] = useState<any>(null);
    const [orderData, setOrderData] = useState<any>(null);
    const [downloading, setDownloading] = useState<number>(-1);
    const viewport = Viewport();
    const [loading, setLoading] = useState<boolean>(true);
    const [hasData, setHasData] = useState<boolean>(false);
    const [mensajeError, setMensajeError] = useState<string>(NO_DATA_FOUND);

    const downloadColilla = async (
        token: string,
        usuario: string,
        colilla: number,
        fecPedido: string,
        serie: string
    ) => {
        await carServices
            .downloadColilla(token, usuario, serie, colilla, fecPedido)
            .then((data) => {
                if (!data) {
                    return;
                }
                const pdfDecode = decode(data.data.downloadColillaPdf);
                const pdf = new Blob([pdfDecode], { type: 'application/pdf' });
                const datacolilla = window.URL.createObjectURL(pdf);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = datacolilla;
                link.download = 'Colilla-' + colilla + '-' + serie;
                link.click();
                window.URL.revokeObjectURL(datacolilla);
                link.remove();
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setDownloading(-1);
            });
    };

    const downloadPdf = (index: number) => {
        setDownloading(index);
        const order = dataHistoryDetail[index];
        downloadColilla(userLogged.token, userLogged.usuario, order.colilla, orderData.date, order.serie);
    }


    useEffect(() => {
        let userData = getData('userData');
        setOrderData(getData('orderData'));

        if (dataUser.isLogged && orderData && dataUser.token) {
            const { usuario, token } = dataUser;
            setUserLogged({ usuario, token })
            getHistoryDetail(orderData, dataUser.token);
        } else if (!userData?.isLogged) {
            window.location.href = siteUrl + '/';
        }
    }, [dataUser]);

    const getHistoryDetail = async (orderData: any, token: string) => {
        setMensajeError(NO_DATA_FOUND);
        setLoading(true);
        setHasData(false);
        let maxTimeout = 60000;
        try {
            const data = await systemServices.getConfisys(['MAX_TIME_REQUEST_GANA']);
            const listConfisys = data.data.getConfisys;
            if (listConfisys && listConfisys[0] && listConfisys[0].valorStr) {
                maxTimeout = Number(listConfisys[0].valorStr);
            }
        } catch (e) {
            console.log(e);
        }
        

        let timeout = setTimeout(( ) => {
            setMensajeError(TIEMPO_EXCEDIDO);
            setLoading(false);
        }, maxTimeout);

        const parseError = (message: string) => {
            if (!message) {
                message = '';
            }
            return message.split("SIGP_SelPedidoreferencias")[0].trim();
        }

        await carServices
            .orderInfo(token, orderData.order, orderData.date)
            .then((data) => {
                const { data: { getOrderInfo } } = data;
                if (getOrderInfo && getOrderInfo.length > 0) {
                    setDataHistoryDetail(getOrderInfo);
                    setHasData(true);
                } else if (data.errors && data.errors.length > 0) {
                    if (data.errors[0].message) {
                        setMensajeError(parseError(data.errors[0].message));
                        console.log(data.errors);
                    }
                }
            })
            .catch((err) => {
                if (err.message) {
                    setMensajeError(parseError(err.message));
                }
                console.log(err);
            }).finally(() => {
                setLoading(false);
                clearTimeout(timeout);
            });
    };

    const itemsView: number = 10;
    const handleLoadMore = () => {
        if (page < dataHistoryDetail.length / itemsView) {
            setPage(page + 1);
        }
    };


    return (
        <>
            {!hasData && (
                <ContainerMain className={loading?'loading':''}>
                    <ContainerCenter>
                        {loading ? (
                            <>
                                <Loading />
                                <TextoLoading>Espere un momento</TextoLoading>
                            </>
                        ) :(
                            <NoDataFound>{mensajeError}</NoDataFound>
                        )}
                    </ContainerCenter>
                </ContainerMain>
            )}
            {hasData && viewport.mobile && (
                <>
                    <ContainerListHistory>
                        {dataHistoryDetail.map((item: any, index: number) => {
                            if (item.numTransaccion !== null) {
                                if (index < page * itemsView) {
                                    return (
                                        <CardHistory key={`card-${index}`}>
                                            <CardHistoryHeader>
                                                <CardItem>
                                                    <div>No. de Transacción</div>
                                                    <div>{item.numTransaccion}</div>
                                                </CardItem>
                                                <CardItem>
                                                    <div>Colilla</div>
                                                    <div>{item.colilla}</div>
                                                </CardItem>
                                                <CardItem>
                                                    <div>Serie</div>
                                                    <div>{item.serie}</div>
                                                </CardItem>
                                                <CardItem>
                                                    <div>Descripción</div>
                                                    <div>{item.desGame}</div>
                                                </CardItem>
                                            </CardHistoryHeader>
                                            <CardHistoryBody>
                                                <div>Acciones</div>
                                                <ButtonContainer>
                                                    <Button
                                                        key={'boton' + index}
                                                        text="Descargar"
                                                        typeButton={downloading == index ? ' active' : ''}
                                                        onClick={() => downloadPdf(index)}
                                                    ></Button>
                                                </ButtonContainer>
                                            </CardHistoryBody>
                                        </CardHistory>
                                    );
                                }
                            }
                        })}

                        {itemsView * page < dataHistoryDetail.length && (
                            <ContainerLoadMore>
                                <Button text="Cargar más" onClick={() => handleLoadMore()}></Button>
                            </ContainerLoadMore>
                        )}
                    </ContainerListHistory>
                </>
            )}

            {hasData && !viewport.mobile && (
                <>
                    <ContainerTableHistory>
                        <HeaderTable>
                            <h4>No. de Transacción</h4>
                            <h4>Colilla</h4>
                            <h4>Serie</h4>
                            <h4>Descripción</h4>
                            <h4>Acciones</h4>
                        </HeaderTable>
                        <ContainerContentTable>
                            <ContentTable activeScroll={true}>
                                {dataHistoryDetail.map((item: any, index: number) => {
                                    if (item.numTransaccion !== null) {
                                        const theme = index % 2 === 1 ? 'light' : 'dark';
                                        if (index < page * itemsView) {
                                            return (
                                                <RowTable
                                                    key={'RowTable' + index}
                                                    className={theme}
                                                >
                                                    <div>{item.numTransaccion}</div>
                                                    <div>{item.colilla}</div>
                                                    <div>
                                                        {item.serie}
                                                    </div>
                                                    <div>{item.desGame}</div>
                                                    <div>
                                                        <Button
                                                            key={'boton' + index}
                                                            text="Descargar"
                                                            typeButton={downloading == index ? ' active' : ''}
                                                            onClick={() => downloadPdf(index)}
                                                        ></Button>
                                                    </div>
                                                </RowTable>
                                            );
                                        }
                                    }
                                })}
                                {itemsView * page < dataHistoryDetail.length && (
                                    <ContainerLoadMore>
                                        <Button
                                            text="Cargar más"
                                            onClick={() => handleLoadMore()}
                                        ></Button>
                                    </ContainerLoadMore>
                                )}
                            </ContentTable>
                        </ContainerContentTable>
                    </ContainerTableHistory>
                </>
            )}
        </>
    );
};

export default TableShoppingHistoryDetail;
