import React, { useState, useEffect } from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import { WrapperShoppingHistoryDetail, ContainerTitle, Title, Subtitle } from './styled';

import Viewport from 'src/shared/hooks/viewport';
import TableShoppingHistoryDetail from '../../molecules/tableShoppingHistoryDetail/tableShoppingHistoryDetail';
import { graphql, useStaticQuery } from 'gatsby';
import { colorText } from '@utils/colorText';
import { getData } from '@utils/transferData';
import siteUrl from '@utils/siteUrl';

const ShoppingHistoryDetail = () => {
    const [orderData, setOrderData] = useState<any>({ reference: null });
    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Historial de compras',
            pathname: 'historial-de-compras/'
        },
        {
            crumbLabel: 'Colillas',
            pathname: 'historial-de-compras/colillas/'
        }
    ];


    const DataHistoryDetail = useStaticQuery(graphql`
        query DataHistoryDetail {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "HistorialDeComprasDetalle" } }) {
                nodes {
                    title
                    pageIcon {
                        description
                        file {
                            url
                        }
                        title
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ pageIcon, title, metaData }]
        }
    } = DataHistoryDetail;

    useEffect(() => {
        const data = getData('orderData');
        if (data && data.reference) {
            setOrderData(data);
        } else {
            window.location.href = siteUrl + '/historial-de-compras/';
        }
    }, []);

    
    return (
        <Layout>
            <SEO
                title={metaData.title}
                description={metaData.descripcion}
                keyWords={metaData.keyWords}
                slug="/historial-de-compras/colillas/"
                index='noindex, nofollow'
            />
            <WrapperShoppingHistoryDetail>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
                <ContainerTitle>
                    <img src={pageIcon.file.url} alt={pageIcon.title} />
                    <Title  dangerouslySetInnerHTML={{ __html: colorText(title) }} />
                    <Subtitle>Referencia: {orderData.reference}</Subtitle>
                </ContainerTitle>
                <TableShoppingHistoryDetail />
            </WrapperShoppingHistoryDetail>
        </Layout>
    );
};

export default ShoppingHistoryDetail;
