import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperShoppingHistoryDetail = styled.div`
    width: 100%;
    height: 100%;
    padding: 17px 17px 40px;
    ${breakpoint('md')`
        margin: auto;
        padding: 36px;

    `};
`;

export const ContainerTitle = styled.div`
    img {
        display: none;
        margin: auto;

        ${breakpoint('md')({
            display: 'block',
            width: '30px'
        })}
    }

    ${breakpoint('md')({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 'auto',
        textAlign: 'center',
        maxWidth: '995px'
    })}
`;

export const Title = styled.h1`
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding: 14px 0 0px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: ${(props) => props.theme.colors.accents.base.yellow};

    span {
        padding-right: 10px;
    }
`;


export const Subtitle = styled.h2`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 14px 15px 20px;
    border-bottom: 1px solid #c5c3c9;
`;